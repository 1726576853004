import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { configManager } from 'Helpers/config-manager';
import { securityClient } from 'securityClient';


const baseQuery = async (args: any, WebApi: any, extraOptions: any) => {
  const config = configManager.getConfig();
  const baseQuery = fetchBaseQuery({
    baseUrl: `${config?.api.httpProtocol}://${config?.api.url}/api/v1/`,
    prepareHeaders: async (headers, { getState }) => {
      const access_token = await securityClient.getAccessTokenSilently()();
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`)
      }
      return headers
    },
  });

  return baseQuery(args, WebApi, extraOptions);
};

export const api = createApi({
  baseQuery,
  tagTypes: ['Integrations', 'Deals', 'Sync', 'Deal', 'Pipelines', 'Owners', 'EmailContent'],
  endpoints: () => ({}),
});