import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Avatar, Box, Button, Checkbox, Drawer, FormControlLabel, IconButton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { compareAsc, differenceInDays, format, subDays } from "date-fns";
import { stringAvatar } from "Helpers/color-helper";
import { ContactView } from "Models/contact-view";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDealQuery } from "Services/deal";
import { useAppSelector } from "State/hooks";
import { v4 } from "uuid";
import { DataItem, DataSet, Timeline, TimelineOptions } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { createRoot } from "react-dom/client";
import TimelineItem from "./TimelineItem";
import { groupBy } from "lodash";
import { CallActivity } from "Models/call-activity";
import { EmailActivity } from "Models/email-activity";
import { MeetingActivity } from "Models/meeting-activity";
import { NoteActivity } from "Models/note-activity";
import { TaskActivity } from "Models/task-activity";
import { Direction } from "Models/direction";
import { StageHistory } from "Models/stage-history";
import MuiTooltip from '@mui/material/Tooltip';
import TimelineStage from "./TimelineStage";
import VerticalTimeline from "./VerticalTimeline";
export interface ContactTimelineOptions {
    marketingTouchpointsEnabled: undefined | boolean;
    salesTouchpointsEnabled: undefined | boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface CustomDataItem extends DataItem {
    activityType: 'call' | 'meeting' | 'email' | 'note' | 'task' | 'stage';
    isThread?: boolean;
    color: string;
    avatarProps?: {
        sx: {
            bgcolor: string;
        };
        children: string;
    };
    contactViewId?: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TimelineV2() {
    let { dealId } = useParams();
    const timelineRef = useRef(null);
    const navigate = useNavigate();
    const [fromDateRequiredError, setFromDateRequiredError] = useState(false);
    const [fromDateAfterToDateError, setFromDateAfterToDateError] = useState(false);
    const [toDateRequiredError, setToDateRequiredError] = useState(false);
    const [toDateBeforeFromDateError, setToDateBeforeFromDateError] = useState(false);
    const [marketingTouchpointsEnabled, setMarketingTouchpointsEnabled] = useState<boolean | undefined>(true);
    const [salesTouchpointsEnabled, setSalesTouchpointsEnabled] = useState<boolean | undefined>(true);
    const [prospectEngagementEnabled, setProspectEngagementEnabled] = useState<boolean | undefined>(true);
    const [systemActionsEnabled, setSystemActionsEnabled] = useState<boolean | undefined>(true);
    const [contactViews, setContactViews] = useState<Array<ContactView>>([]);
    const companyId = useAppSelector((state) => state.company.companyId);
    const { data: deal } = useGetDealQuery({ companyId: companyId, dealId: dealId ?? '' }, { skip: !dealId || !companyId });
    const [selectedContactViews, setSelectedContactViews] = useState<Array<ContactView>>([]);
    const [startDate, setStartDate] = useState<undefined | Date>(undefined);
    const [endDate, setEndDate] = useState<undefined | Date>(undefined);
    const elementMapRef = useRef<any>({});
    const [value, setValue] = useState(0);
    const [openSidePanel, setOpenSidePanel] = useState(false);
    const [selectedItem, setSelectedItem] = useState<(EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity)>();
    const [activities, setActivities] = useState<Array<EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity>>([]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (deal?.rangeStartDate && deal?.rangeEndDate) {
            const contactViews = deal.contacts.map((contact) => {
                return {
                    ...contact,
                    hide: false,
                    viewId: v4(),
                    avatarProps: stringAvatar(`${contact.firstName} ${contact.lastName}`)
                }
            });
            contactViews.sort((a, b) => a.firstName?.localeCompare(b?.firstName));
            setSelectedContactViews([...contactViews]);
            setContactViews([...contactViews]);
            setStartDate(new Date(deal.rangeStartDate));
            setEndDate(new Date(deal.rangeEndDate));
        }
    }, [deal?.rangeStartDate, deal?.rangeEndDate, deal?.contacts])

    const navigateToHome = useCallback(() => {
        navigate('/home');
    }, [navigate])

    const onSidePanelOpen = useCallback(() => {
        setOpenSidePanel(true);
    }, [])

    const openVerticalTimeline = useCallback((item: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity | StageHistory) & CustomDataItem) => {
        switch (item.activityType) {
            case "call":
            case "email":
            case "meeting":
            case "note":
            case "task":
                setSelectedItem(item as (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity));
                onSidePanelOpen();
                break;
            default:
                break;
        }
    }, [onSidePanelOpen])

    const sortActivities = useCallback((activities: Array<EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity>) => {
        return activities.sort((a, b) => (new Date(a.createDate).getTime() - new Date(b.createDate).getTime()));
    }, [])

    useEffect(() => {
        let timeline: Timeline | null = null;
        if (timelineRef?.current) {
            if (deal && startDate && endDate) {
                let activities = new Array<(EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity | StageHistory) & CustomDataItem>();
                for (let i = 0; i < selectedContactViews.length; i++) {
                    const contact = selectedContactViews[i];
                    // Set all activities
                    if (contact?.calls?.length > 0) {
                        let filteredCalls = contact.calls;
                        if (!marketingTouchpointsEnabled) {
                            filteredCalls = contact?.calls.filter((call) => {
                                return call.direction !== Direction.Inbound
                            });
                        }

                        if (!salesTouchpointsEnabled) {
                            filteredCalls = contact?.calls.filter((call) => {
                                return call.direction !== Direction.Outbound
                            });
                        }

                        activities = activities.concat(filteredCalls.map((call) => {
                            return {
                                ...call,
                                avatarProps: contact.avatarProps,
                                contactViewId: contact.viewId,
                                activityType: 'call',
                                type: 'box',
                                start: call.createDate,
                                content: call.content,
                                touchpointType: call.direction === Direction.Inbound ? "Marketing" : "Sales"
                            } as CallActivity & CustomDataItem;
                        }));
                    }
                    if (contact?.meetings?.length > 0) {
                        let filteredMeetings = contact.meetings;
                        if (!salesTouchpointsEnabled) {
                            filteredMeetings = filteredMeetings.filter((meeting) => {
                                return meeting.outcome !== '  COMPLETED';
                            });
                        }
                        activities = activities.concat(filteredMeetings.map((meeting) => {
                            return {
                                ...meeting,
                                avatarProps: contact.avatarProps,
                                contactViewId: contact.viewId,
                                type: 'box',
                                start: meeting.createDate,
                                content: meeting.content,
                                activityType: 'meeting',
                            } as MeetingActivity & CustomDataItem
                        }));
                    }
                    if (contact?.emails?.length > 0) {
                        let filteredEmails = contact?.emails;
                        if (!marketingTouchpointsEnabled) {
                            filteredEmails = filteredEmails.filter((email) => {
                                return email.direction !== Direction.Inbound
                            });
                        }

                        if (!salesTouchpointsEnabled) {
                            filteredEmails = filteredEmails.filter((email) => {
                                return email.direction !== Direction.Outbound
                            });
                        }
                        const emailsGrouped = groupBy(filteredEmails, (email) => {
                            return email.title;
                        });

                        const emailActivityGroupedByTitle = Object.keys(emailsGrouped).map((title) => {
                            if (emailsGrouped[title].length === 1) {
                                const email = emailsGrouped[title][0];
                                return {
                                    ...email,
                                    activityType: 'email',
                                    type: 'box',
                                    avatarProps: contact.avatarProps,
                                    contactViewId: contact.viewId,
                                    start: email.createDate,
                                    content: `${format(new Date(email.createDate), "MM/dd/yyyy")}`,
                                    isThread: false,
                                    touchpointType: email.direction === Direction.Inbound ? "Marketing" : "Sales"
                                } as EmailActivity & CustomDataItem
                            } else {
                                const minEmailDate = Math.min(...emailsGrouped[title].map((email) => new Date(email.createDate).getTime()));
                                const maxEmailDate = Math.max(...emailsGrouped[title].map((email) => new Date(email.createDate).getTime()));
                                const email = emailsGrouped[title][0];
                                return {
                                    ...email,
                                    activityType: 'email',
                                    type: 'box',
                                    avatarProps: contact.avatarProps,
                                    contactViewId: contact.viewId,
                                    start: new Date(minEmailDate),
                                    content: `${format(new Date(minEmailDate), "MM/dd/yyyy")} - ${format(new Date(maxEmailDate), "MM/dd/yyyy")}`,
                                    widthOverride: "153px",
                                    isThread: true
                                }
                            }
                        }) as Array<EmailActivity & CustomDataItem>;

                        // Group emails by date
                        const emailActivityGroupedByDate = groupBy(emailActivityGroupedByTitle, (email) => {
                            return new Date(email.createDate).toISOString().substring(0, 10);
                        });

                        const finalEmailList = Object.keys(emailActivityGroupedByDate).map((date) => {
                            const firstEmail = emailActivityGroupedByDate[date][0];

                            if (emailActivityGroupedByDate[date].length === 1) {
                                return {
                                    ...emailActivityGroupedByDate[date][0],
                                    type: 'box',
                                    count: 0
                                }
                            } else {
                                return {
                                    ...firstEmail,
                                    type: 'box',
                                    isThread: false,
                                    count: emailActivityGroupedByDate[date].length
                                }
                            }
                        }) as Array<EmailActivity & CustomDataItem>;

                        activities = activities.concat(finalEmailList);
                    }
                    if (contact?.tasks?.length > 0) {
                        const filteredTasks = contact?.tasks;
                        activities = activities.concat(filteredTasks.map((task) => {
                            return {
                                ...task,
                                type: 'box',
                                avatarProps: contact.avatarProps,
                                contactViewId: contact.viewId,
                                start: task.createDate,
                                content: task.content,
                                activityType: 'task'
                            } as TaskActivity & CustomDataItem
                        }));
                    }
                    if (contact?.notes?.length > 0) {
                        const filteredNotes = contact?.notes;
                        activities = activities.concat(filteredNotes.map((note) => {
                            return {
                                ...note,
                                type: 'box',
                                avatarProps: contact.avatarProps,
                                contactViewId: contact.viewId,
                                start: note.createDate,
                                content: `${format(new Date(note.createDate), "MM/dd/yyyy")}`,
                                activityType: 'note'
                            } as NoteActivity & CustomDataItem
                        }));
                    }
                }
                setActivities([...sortActivities(activities as Array<EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity>)]);
                const sortedStages = [...deal.stageHistory].sort((a, b) => (new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
                let rangeEndRunningDate = new Date(endDate).getTime();
                let switcher = true;
                sortedStages.forEach((stage, index) => {
                    const diff = differenceInDays(rangeEndRunningDate, new Date(stage.timestamp).getTime());
                    activities.push({
                        ...stage,
                        start: new Date(startDate),
                        end: new Date(endDate),
                        content: stage.stageTitle,
                        type: 'background',
                        className: switcher ? 'negative' : 'positive',
                        activityType: 'stage',
                        color: `hsl('${((diff / differenceInDays(new Date(endDate), subDays(new Date(startDate).getTime(), 1)))) * 360}', 100%, 50%)}`
                    });
                    switcher = !switcher;
                    rangeEndRunningDate = new Date(stage.timestamp).getTime();
                });
                const container = timelineRef.current as any;
                const options: TimelineOptions = {
                    width: '100%',
                    height: 'calc(100vh - 385px)',
                    template: function (item: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity) & CustomDataItem, element: any) {
                        if (!item || !item?.id) return;

                        const mapId = item?.id;
                        if (elementMapRef.current?.[mapId]) return elementMapRef.current[mapId];

                        // Create a container for the react element (prevents DOM node errors)
                        const container = document.createElement('div');
                        element.appendChild(container);
                        if (item.activityType !== 'stage') {
                            createRoot(container).render(<TimelineItem item={item} onClick={openVerticalTimeline} />);
                        } else {
                            createRoot(container).render(<TimelineStage item={item as any} />);
                        }

                        // Store the rendered element container to reference later
                        elementMapRef.current[mapId] = container;

                        // Return the new container
                        return container;
                    },
                    stack: true,
                    horizontalScroll: true,
                    verticalScroll: true,
                    orientation: {
                        axis: 'both',
                        item: 'top',
                    },
                    margin: {
                        item: 15,
                        axis: 100,
                    },
                    editable: false,
                    start: startDate,
                    end: endDate,
                };

                (options as any).loadingScreenTemplate = () => {
                    return '<h4>Loading...</h4>'
                }

                timeline = new Timeline(container, new DataSet(activities), options);

            }
        }

        return () => {
            timeline?.destroy();
        };
    }, [deal, endDate, marketingTouchpointsEnabled, onSidePanelOpen, openVerticalTimeline, prospectEngagementEnabled, salesTouchpointsEnabled, selectedContactViews, sortActivities, startDate])

    const handleValueChange = useCallback((event: React.SyntheticEvent, values: ContactView[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<ContactView> | undefined) => {
        if (details) {
            if (reason === 'removeOption' && details) {
                const newValues = values.filter((val) => {
                    return details.option.viewId !== val.viewId;
                });
                newValues.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setSelectedContactViews(newValues);
            } else if (reason === 'selectOption') {
                values.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setSelectedContactViews(values);
            }
        }
    }, [])

    const onFromDateChange = useCallback((fromDate: Date | null) => {
        if (fromDate && !isNaN(fromDate.getTime()) && endDate) {
            if (compareAsc(fromDate, endDate) === 1) {
                setFromDateAfterToDateError(true);
            } else {
                setFromDateRequiredError(false);
                setFromDateAfterToDateError(false);
                setStartDate(fromDate);
            }
        } else {
            setFromDateRequiredError(true);
        }
    }, [endDate])

    const onToDateChange = useCallback((toDate: Date | null) => {
        if (toDate && !isNaN(toDate.getTime()) && startDate) {
            if (compareAsc(startDate, toDate) === 1) {
                setToDateBeforeFromDateError(true);
            } else {
                setToDateBeforeFromDateError(false);
                setToDateRequiredError(false);
                setEndDate(toDate);
            }
        } else {
            setToDateRequiredError(true);
        }
    }, [startDate])

    const resetDates = useCallback(() => {
        if (deal) {
            setStartDate(new Date(deal.rangeStartDate));
            setEndDate(new Date(deal.rangeEndDate));
            setToDateBeforeFromDateError(false);
            setToDateRequiredError(false);
            setFromDateRequiredError(false);
            setFromDateAfterToDateError(false);
        }
    }, [deal])

    const marketingTouchpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarketingTouchpointsEnabled(event.target.checked);
    };


    const salesTouchpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSalesTouchpointsEnabled(event.target.checked);
    };


    const prospectEngagementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProspectEngagementEnabled(event.target.checked);
    };

    const systemActionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSystemActionsEnabled(event.target.checked);
    };

    const onSidePanelClose = useCallback(() => {
        setSelectedItem(undefined);
        setOpenSidePanel(false);
    }, [])

    return <>{deal && endDate && <Box>
        <Box padding="5px 20px 5px 20px" marginBottom="5px" position="sticky" top="0" zIndex="1000" sx={{ background: "white" }} boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;">
            <Box margin="15px 0px 15px 0px">
                <Button variant="contained" onClick={navigateToHome} startIcon={<ChevronLeftIcon />} style={{ backgroundColor: "#13033af0" }}><Box>
                    <Typography textTransform={'none'} style={{ paddingTop: "4px" }}>Deals</Typography>
                </Box>
                </Button>
            </Box>
            <Box display="flex">
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h4" gutterBottom>
                            {deal.summary.name}
                        </Typography>
                        <Box display="flex" height="50px" justifyContent="center">
                            <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                <Typography><strong>Owner:</strong> {deal.summary.owner}</Typography>
                                <Typography><strong>Type:</strong> {deal.summary.dealType}</Typography>
                            </Box>
                            <Box marginLeft="50px" marginRight="50px" borderLeft="2px solid gray" height="100%"></Box>
                            <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                <Typography><strong>Create date:</strong> {format(new Date(deal.summary.createDate), 'MM/dd/yyyy')}</Typography>
                                <Typography><strong>Close date:</strong> <span style={{ color: "#07B642" }}>{(deal.summary.closeDate) ? format(new Date(deal.summary.closeDate), 'MM/dd/yyyy') : ''}</span></Typography>
                            </Box>
                            <Box marginLeft="50px" marginRight="50px" borderLeft="2px solid gray" height="100%"></Box>
                            <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                <Typography><strong>Amount:</strong> ${deal.summary.amount}</Typography>
                                <Typography>&nbsp;</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                        <Box display="flex" flexDirection="column" border="1px solid rgba(0, 0, 0, 0.23)" padding="8px 0px 8px 8px" borderRadius="4px">
                            <Box display="flex" flex="1">
                                <Box display="flex" paddingRight="10px" flex="1" flexDirection="column">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date range: From Date"
                                            slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
                                            minDate={subDays(new Date(deal.rangeStartDate).getTime(), 1)}
                                            value={startDate}
                                            onChange={onFromDateChange}
                                            maxDate={new Date(endDate)} />
                                    </LocalizationProvider>
                                    {fromDateRequiredError && <Box color="red">From date is required.</Box>}
                                    {fromDateAfterToDateError && <Box color="red">From cannot be after To date.</Box>}
                                </Box>
                                <Box display="flex" flex="1" flexDirection="column">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date range: To Date"
                                            value={endDate}
                                            slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
                                            minDate={startDate}
                                            onChange={onToDateChange}
                                            maxDate={new Date(deal.rangeEndDate)} />
                                    </LocalizationProvider>
                                    {toDateRequiredError && <Box color="red">To date is required.</Box>}
                                    {toDateBeforeFromDateError && <Box color="red">To cannot be before From date.</Box>}
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <IconButton aria-label="delete" onClick={resetDates} size="small" title="Reset dates">
                                        <RestartAltIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box padding="10px" margin="10px" sx={{ backgroundColor: "white" }} boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Options">
                    <Tab label="Filter by Type of Touchpoints" {...a11yProps(0)} />
                    <Tab label="Sorted by Contacts" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Box display="inline-flex" justifyContent="flex-end" alignItems="center" flexShrink="0" padding="10px" marginRight="10px">
                    <MuiTooltip title={<Box><Typography>All inbound calls and emails</Typography></Box>}><FormControlLabel control={
                        <Checkbox
                            onChange={marketingTouchpointsChange}
                            checked={marketingTouchpointsEnabled}
                            sx={{
                                color: "rgba(250, 6, 240, 0.63)",
                                '&.Mui-checked': {
                                    color: "rgba(250, 6, 240, 0.63)",
                                },
                            }}
                        />} label="Marketing Touchpoints" /></MuiTooltip>
                </Box>
                <Box display="inline-flex" justifyContent="flex-end" alignItems="center" flexShrink="0" padding="10px" marginRight="10px">
                    <MuiTooltip title={<Box><ul style={{ paddingLeft: "15px" }}>
                        <li><Typography>All outbound calls and emails</Typography></li>
                        <li><Typography>All completed meetings</Typography></li>
                    </ul></Box>}>
                        <FormControlLabel control={
                            <Checkbox
                                onChange={salesTouchpointsChange}
                                checked={salesTouchpointsEnabled}
                                sx={{
                                    color: "#0690F9",
                                    '&.Mui-checked': {
                                        color: "#0690F9",
                                    },
                                }}
                            />} label="Sales Touchpoints" /></MuiTooltip>
                </Box>
                <Box display="inline-flex" justifyContent="flex-end" alignItems="center" flexShrink="0" padding="10px" marginRight="10px">
                    <FormControlLabel control={
                        <Checkbox
                            checked={prospectEngagementEnabled}
                            onChange={prospectEngagementChange}
                            sx={{
                                color: "#A378FF",
                                '&.Mui-checked': {
                                    color: "#A378FF",
                                },
                            }}
                        />} label="Prospect Engagement" />
                </Box>
                <Box display="inline-flex" justifyContent="flex-end" alignItems="center" flexShrink="0" padding="10px" marginRight="10px">
                    <FormControlLabel control={
                        <Checkbox
                            checked={systemActionsEnabled}
                            onChange={systemActionsChange}
                            sx={{
                                color: "#606060",
                                '&.Mui-checked': {
                                    color: "#606060",
                                },
                            }}
                        />} label="System Actions" />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Box padding="10px">
                    <Autocomplete
                        value={selectedContactViews}
                        onChange={handleValueChange}
                        isOptionEqualToValue={(contact, value) => (contact.viewId === value.viewId)}
                        multiple
                        options={contactViews}
                        limitTags={5}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Box display="flex" alignItems="center" justifyItems="center" gap="5px">
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                    />
                                    <Avatar sx={{ background: option.avatarProps?.sx.bgcolor, width: 22, height: 22, lineHeight: 15, fontSize: "10px" }}>{option.avatarProps?.children}</Avatar>
                                    {option.firstName + " " + option.lastName}
                                </Box>
                            </li>
                        )}
                        style={{ width: '50%' }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select contacts" placeholder="Add contacts" />
                        )}
                    />
                </Box>
            </CustomTabPanel>
        </Box>
        <Box ref={timelineRef} margin="10px"></Box>
        <Drawer
            anchor="right"
            PaperProps={{
                sx: { width: "40%" },
            }}
            open={openSidePanel}
            onClose={() => onSidePanelClose()}>
            {selectedItem && <VerticalTimeline activities={activities} selectedItem={selectedItem} onClose={onSidePanelClose} />}
        </Drawer>
    </Box>}</>
}
