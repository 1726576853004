import { StageHistory } from "Models/stage-history";
import { CustomDataItem } from ".";
import { Box } from "@mui/material";

export interface TimelineItemProps {
    item: StageHistory & CustomDataItem;
}

export default function TimelineStage({ item }: TimelineItemProps) {
    return <>{item && <Box fontWeight="bold" color="black">
        {item.stageTitle}
    </Box>}</>
}