import { CallActivity } from "Models/call-activity";
import { EmailActivity } from "Models/email-activity";
import { MeetingActivity } from "Models/meeting-activity";
import { NoteActivity } from "Models/note-activity";
import { TaskActivity } from "Models/task-activity";
import { CustomDataItem } from ".";
import { format } from 'date-fns'
import { Avatar, Box } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneIcon from '@mui/icons-material/Phone';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { StageHistory } from "Models/stage-history";
import { useCallback } from "react";

export interface TimelineItemProps {
    item: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity | StageHistory) & CustomDataItem;
    onClick: (item: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity | StageHistory) & CustomDataItem) => void;
}

export default function TimelineItem({ item, onClick }: TimelineItemProps) {
    const getDotColor = useCallback((item: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity | StageHistory) & CustomDataItem) => {
        switch (item.activityType) {
            case "call":
                const callItem = item as CallActivity;
                if (callItem.touchpointType === "Marketing") {
                    return {
                        '&::after': {
                            backgroundColor: 'rgba(250, 6, 240, 0.63)'
                        }
                    }
                } else {
                    return {
                        '&::after': {
                            backgroundColor: '#0690F9'
                        }
                    }
                }
            case "meeting":
                const meetingItem = item as MeetingActivity;
                if (meetingItem.outcome === '  COMPLETED') {
                    return {
                        '&::after': {
                            backgroundColor: '#0690F9'
                        }
                    }
                } else {
                    return {
                        '&::after': {
                            backgroundColor: '#0690F9'
                        }
                    }
                }
            case "email":
                const emailItem = item as EmailActivity;
                if (emailItem.touchpointType === "Marketing") {
                    return {
                        '&::after': {
                            backgroundColor: 'rgba(250, 6, 240, 0.63)'
                        }
                    }
                } else {
                    return {
                        '&::after': {
                            backgroundColor: '#0690F9'
                        }
                    }
                }
            default:
                break;
        }

    }, [])

    return <>{item && <Box height="100%" display="flex" width="100%" className="rvo-dot" sx={getDotColor(item)}>
        <Box display="flex" padding="3px 15px" gap="5px" onClick={() => onClick(item)} sx={{
            '&:hover': {
                cursor: 'pointer',
            },
        }}>
            {item.activityType !== 'stage' && <Box>
                <Avatar sx={{ background: item.avatarProps?.sx.bgcolor, width: 22, height: 22, lineHeight: 15, fontSize: "10px" }}>{item.avatarProps?.children}</Avatar>
            </Box>}
            {item.activityType !== 'stage' && <Box>
                {(item as any).stageTitle}
            </Box>}
            <Box display="flex" alignItems="center" gap="5px">
                {item.activityType === 'meeting' && <GroupsIcon className="timeline-icon" />}
                {item.activityType === 'task' && <AssignmentTurnedInIcon className="timeline-icon" />}
                {item.activityType === 'note' && <EditNoteOutlinedIcon className="timeline-icon" />}
                {item.activityType === 'call' && <PhoneIcon className="timeline-icon" />}
                {item.activityType === 'email' && (item as EmailActivity).isThread && <DynamicFeedIcon className="timeline-icon" />}
                {item.activityType === 'email' && !(item as EmailActivity).isThread && (item as EmailActivity).count === 0 && <EmailIcon className="timeline-icon" />}
                {item.activityType === 'email' && !(item as EmailActivity).isThread && (item as EmailActivity).count > 0 && <FilterNoneIcon color="action" className="timeline-icon" />}
                {item.activityType === 'email' && (item as EmailActivity).isThread && <Box>{(item as EmailActivity).range}</Box>}
                {item.activityType === 'email' && !(item as EmailActivity).isThread && <Box>{format(new Date(item.start), 'MM/dd/yyyy')}</Box>}
                {item.activityType !== 'email' && <Box className="date">{format(new Date(item.start), 'MM/dd/yyyy')}</Box>}
            </Box>
        </Box>
    </Box>}</>
}