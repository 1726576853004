import { Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "Guards/AuthGuard";
import Integrations from "Pages/Integrations";
import HSCallback from "Pages/Integrations/HSCallback";
import SFCallback from "Pages/Integrations/SFCallback";
import Dashboard from "Pages/Dashboard";
import Home from "Pages/Home";
import Timeline from "Pages/Timeline";
import TimelineV2 from "Pages/TimelineV2";

export default function AppRoutes() {
  return (

    <Routes>
      <Route path="/" element={<AuthenticationGuard component={Home} />} />
      <Route path="/timeline/:dealId" element={<TimelineV2 />} />
      <Route path="/timelinev2/:dealId" element={<Timeline />} />
      <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />} />
      <Route path="/home" element={<AuthenticationGuard component={Home} />} />
      <Route path="/integrations" element={<AuthenticationGuard component={Integrations} />} />
      <Route path="/oauth-callback-hs" element={<AuthenticationGuard component={HSCallback} />} />
      <Route path="/oauth-callback-sf" element={<AuthenticationGuard component={SFCallback} />} />
      <Route path="*" element={<AuthenticationGuard component={NotFound} />} />
    </Routes>
  );
}

function NotFound() {
  console.debug("NotFound");
  return (
    <>
      <h1>Not found</h1>
    </>
  );
}

