import { LinearProgressProps, Box, LinearProgress, Typography, Link, FormControl } from "@mui/material";
import { useGetMessagesQuery } from "Services/message";
import { useAppSelector } from "State/hooks";
import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useGetIntegrationsQuery, useSyncMutation } from "Services/company";
import { Integration } from "Models/integration";
import { IntegrationType } from "Models/integration-type";
import { dealApi, useGetDealsMutation } from 'Services/deal';
import { SyncStatus } from "Models/sync-status";
import { DealSummary } from "Models/deal-summary";
import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import { PagedResult } from 'Models/paged-result';
import { FilterParam, PaginationFilterSortParams } from "Models/pagination-filter-sort-params";
import { useFormik } from "formik";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useGetPipelinesQuery } from "Services/pipeline";
import { Pipeline } from "Models/pipeline";
import { useGetOwnersQuery } from "Services/owner";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface FormValues {
  name: string;
  stage: string;
  dealType: string;
  owner: string;
}

export default function Dashboard() {
  const formik = useFormik<FormValues>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: '',
      stage: '',
      dealType: '',
      owner: ''
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const [pagedDealResult, setPagedDealResult] = useState<PagedResult<Array<DealSummary>> | undefined>();
  const companyId: string = useAppSelector((state) => state.company.companyId);
  const { data: integrationData, refetch: fetchIntegrations } = useGetIntegrationsQuery(companyId, { skip: !companyId });
  const { data: pipelinesData, refetch: fetchPipelines } = useGetPipelinesQuery(companyId, { skip: !companyId });
  const { data: ownersData, refetch: fetchOwners } = useGetOwnersQuery(companyId, { skip: !companyId });
  const [progress, setProgress] = useState(0);
  const [synced, setSynced] = useState<SyncStatus>(SyncStatus.NotStarted);
  const [message, setMessage] = useState('');
  const { data } = useGetMessagesQuery(companyId, { skip: !companyId });
  const [fetchDeals] = useGetDealsMutation();
  const navigate = useNavigate();
  const [syncMutation] = useSyncMutation();
  const [selectedType, setSelectedType] = useState<Pipeline | undefined>();
  const [queryParam, setQueryParam] = useState<PaginationFilterSortParams>({
    paginationParams: {
      page: 0,
      pageSize: 10,
    },
    sortParams: {
      field: 'name',
      direction: 'asc',
    }
  });
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(0);
  const columns: GridColDef<DealSummary>[] = [
    {
      field: 'name', headerName: 'Name', width: 450, renderCell: (params) => {
        return (
          <Link component="button" variant="body2" onClick={() => openTimeline(params.row)}>
            {params.value}
          </Link>
        );
      },
    },
    { field: 'dealType', headerName: 'Type', width: 100 },
    { field: 'stage', headerName: 'Stage', width: 250 },
    {
      field: 'createDate', headerName: 'Create Date', width: 150,
      valueGetter: (value, row) => {
        if (row.createDate) {
          return format(new Date(row.createDate).getTime(), 'MM/dd/yyyy');
        }
        return ''
      },
    },
    {
      field: 'closeDate', headerName: 'Close Date', width: 150,
      valueGetter: (value, row) => {
        if (row.closeDate) {
          return format(new Date(row.closeDate).getTime(), 'MM/dd/yyyy');
        }
        return ''
      },
    },
    { field: 'age', headerName: 'Age', width: 50 },
    { field: 'amount', headerName: 'Amount', width: 150 },
    { field: 'noOfContacts', headerName: 'No. of contacts', width: 150, sortable: false },
    { field: 'owner', headerName: 'Owner', width: 150 },
  ];

  useEffect(() => {
    if (data && data.length > 0) {
      const lastItem = data[data.length - 1];
      setMessage(lastItem.message);
      setProgress(lastItem.progress);
      if (lastItem.progress === 100) {
        fetchIntegrations();
      }
    }
  }, [data, fetchIntegrations])

  useEffect(() => {
    if (integrationData) {
      if (integrationData.length > 0) {
        const hubSpot = integrationData.find((int: Integration) => int.type === IntegrationType.hs);
        if (hubSpot) {
          fetchDeals({
            companyId: companyId,
            params: queryParam
          }).then((response) => {
            if ('data' in response) {
              setPagedDealResult(response.data);
            }
          });
          setSynced(hubSpot.syncStatus);
        }
        // const salesforce = integrationData.find((int: Integration) => int.type === IntegrationType.sf);
        // if (!salesforce) {
        //   fetchSalesforceDeals(true);
        // }
      }
    }

  }, [companyId, fetchDeals, integrationData, queryParam])

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pagedDealResult?.count !== undefined
        ? pagedDealResult?.count
        : prevRowCountState,
    );
  }, [pagedDealResult?.count])

  const openTimeline = (dealSummary: DealSummary) => {
    navigate(`/timeline/${dealSummary.deal_Id}`);
  }

  const completeSync = async () => {
    await syncMutation({ companyId: companyId, reSync: false });
  }

  const handlePageModelChange = useCallback((pageModel: GridPaginationModel) => {
    setQueryParam({
      ...queryParam,
      paginationParams: {
        page: pageModel.page,
        pageSize: pageModel.pageSize
      }
    });
  }, [queryParam]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      setQueryParam({
        ...queryParam,
        paginationParams: {
          page: 0,
          pageSize: 10
        },
        sortParams: {
          field: sortModel[0].field,
          direction: sortModel[0].sort ?? 'asc'
        }
      });
    } else {
      setQueryParam({
        ...queryParam,
        paginationParams: {
          page: 0,
          pageSize: 10
        },
        sortParams: undefined
      });
    }
  }, [queryParam]);

  const onSubmit = (values: FormValues) => {
    let filterParams: FilterParam = {};
    filterParams.dealType = (values.dealType) ? values.dealType : undefined;
    filterParams.name = (values.name) ? values.name : undefined;
    filterParams.stage = (values.stage) ? values.stage : undefined;
    filterParams.owner = (values.owner) ? values.owner : undefined;
    setQueryParam({
      sortParams: undefined,
      paginationParams: {
        page: 0,
        pageSize: 10
      },
      filterParams,
    });
  }

  const handlePipelineChange = (event: SelectChangeEvent<string>) => {
    const pipeline = pipelinesData?.find(p => p.label === event.target.value);
    if (pipeline) {
      formik.setFieldValue('dealType', pipeline.label);
      setSelectedType(pipeline);
    } else {
      formik.setFieldValue('dealType', '');
      formik.setFieldValue('stage', '');
      setSelectedType(undefined);
    }
  }

  const handleStageChange = (event: SelectChangeEvent<string>) => {
    console.log(event);
    if (event?.target?.value) {
      formik.setFieldValue('stage', event.target.value);
    } else {
      formik.setFieldValue('stage', '');
    }
  }

  return <>
    <div>{message}</div>
    {(synced === SyncStatus.NotStarted || synced === SyncStatus.Inprogress) &&
      <Box>
        <LinearProgressWithLabel value={progress} />
        {synced === SyncStatus.Inprogress && <Box>
          Sync was not completed. Click <Link
            component="button"
            variant="body2"
            onClick={completeSync}
          >
            here
          </Link> to complete the sync.
        </Box>}
      </Box>}
    {synced === SyncStatus.Completed &&
      <Box display="flex" flexDirection="column" height="800" width="100%" >
        <Box boxShadow="rgba(0, 0, 0, 0.15) 0px 3px 8px;" display="flex" m="20px" p="20px">
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box>
              <Typography variant="h5">Filters</Typography>
            </Box>
            <Box display="flex">
              <FormControl sx={{ m: 1, width: "50%" }} size="small">
                <TextField
                  size="small"
                  color="secondary"
                  id="name"
                  name="name"
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small" fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  id="dealType"
                  name="dealType"
                  fullWidth
                  color="secondary"
                  size="small"
                  value={formik.values.dealType}
                  label="Type"
                  onChange={handlePipelineChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {pipelinesData?.map((pipeline, index) => {
                    return <MenuItem value={pipeline.label} key={index}>{pipeline.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small" fullWidth>
                <InputLabel id="stage-label">Stage</InputLabel>
                <Select
                  labelId="stage-label"
                  id="stage"
                  name="stage"
                  fullWidth
                  color="secondary"
                  size="small"
                  value={formik.values.stage}
                  label="Stage"
                  onChange={handleStageChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {selectedType?.stages.map((stage, index) => {
                    return <MenuItem value={stage.label} key={index}>{stage.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 }} size="small" fullWidth>
                <InputLabel id="owner-label">Owner</InputLabel>
                <Select
                  labelId="owner-label"
                  id="owner"
                  name="owner"
                  fullWidth
                  color="secondary"
                  size="small"
                  value={formik.values.owner}
                  label="Owner"
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ownersData?.map((owner, index) => {
                    return <MenuItem value={owner.name} key={index}>{owner.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box mt="10px">
              <Button sx={{ color: 'white', backgroundColor: "#13033af0" }} variant="contained" type="submit">
                Search
              </Button>
            </Box>
          </form>
        </Box>
        <Box boxShadow="rgba(0, 0, 0, 0.15) 0px 3px 8px;" display="flex" m="20px">
          <DataGrid rows={pagedDealResult?.data ?? []}
            columns={columns} getRowId={(row) => row.deal_Id}
            rowCount={rowCountState}
            pageSizeOptions={[5, 10, 100]}
            paginationMode="server"
            sortingMode="server"
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            paginationModel={queryParam.paginationParams}
            disableDensitySelector
            onSortModelChange={handleSortModelChange}
            onPaginationModelChange={handlePageModelChange} />
        </Box>

      </Box>
    }
  </>;
}