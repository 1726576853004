import { HubspotIntegration } from 'Models/hubspot-integration';
import { api } from './api'
import { Integration } from "Models/integration";
import { SalesforceIntegration } from 'Models/salesforce-integration';

export const integrationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getIntegrations: build.query<Array<Integration>, string|undefined>({
            query: (companyId) => ({ url: `company/${companyId}/integrations` }),
            providesTags: ['Integrations'],
        }),
        initializeHubspot: build.mutation<null, Partial<HubspotIntegration>>({
            query({companyId, authCode}){
                return {
                    url: `integration/hubspot/company/${companyId}/auth/access-token/${authCode}`,
                    method: 'POST',
                    body: {},
                  }
            },
            invalidatesTags: ['Integrations'],
        }),
        initializeSalesforce: build.mutation<null, Partial<SalesforceIntegration>>({
            query({companyId, authCode}){
                return {
                    url: `integration/salesforce/company/${companyId}/auth/access-token/${authCode}`,
                    method: 'POST',
                    body: {},
                  }
            },
            invalidatesTags: ['Integrations'],
        }),
        sync: build.mutation<null, Partial<{companyId: string, reSync: boolean}>>({
            query({companyId, ...request}){
                return {
                    url: `integration/hubspot/company/${companyId}/sync`,
                    method: 'POST',
                    body: request,
                  }
            },
            invalidatesTags: ['Integrations'],
        }),
        getEmailContent: build.query<any, Partial<{companyId: string, emailId: string}>>({
            query: ({companyId, emailId}) => ({ url: `integration/hubspot/company/${companyId}/sync/email/${emailId}` }),
            providesTags: ['EmailContent'],
        }),
    })
});

export const {
    endpoints: { getIntegrations },
} = integrationApi

export const {
    useLazyGetEmailContentQuery,
    useGetIntegrationsQuery,
    useInitializeHubspotMutation,
    useInitializeSalesforceMutation,
    useSyncMutation
} = integrationApi