import { useCallback, useState } from 'react';
import { Typography, Card, CardContent, Box, IconButton, CardActions, Chip, Button, Link, Avatar } from '@mui/material';
import { CallActivity } from 'Models/call-activity';
import { EmailActivity } from 'Models/email-activity';
import { MeetingActivity } from 'Models/meeting-activity';
import { NoteActivity } from 'Models/note-activity';
import { TaskActivity } from 'Models/task-activity';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useLazyGetEmailContentQuery } from 'Services/company';
import { useAppSelector } from 'State/hooks';
import { stringAvatar } from 'Helpers/color-helper';
export interface VerticalTimelineProps {
  selectedItem: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity);
  activities: Array<EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity>;
  onClose: () => void;
}

export default function VerticalTimeline(props: VerticalTimelineProps) {
  const [currentActivity, setCurrentActivity] = useState<(EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity)>(props.selectedItem);
  const [getEmailContents] = useLazyGetEmailContentQuery();
  const companyId = useAppSelector((state) => state.company.companyId);
  const [email, setEmail] = useState<string>();

  const viewEmail = useCallback(async () => {
    const email = await getEmailContents({
      companyId: companyId,
      emailId: (currentActivity as EmailActivity).sourceId
    }).unwrap();
    setEmail(email.content);
  }, [companyId, currentActivity, getEmailContents])

  const getCard = useCallback((activity: (EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity)) => {
    switch (activity.activityType) {
      case 'note':
        return <Box width="100%" display="flex" alignItems="center" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            marginTop="20px"
            alignItems="start">
            <Box top="-15px" display="flex" sx={{ background: "white" }} position="absolute" padding="5px" borderRadius="50%" boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
              <EditNoteOutlinedIcon sx={{ color: "#A0A0A0" }} />
            </Box>
          </Box>
          <Card
            elevation={3}
            style={{
              width: "90%",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
            }}
          >
            <CardContent sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Box dangerouslySetInnerHTML={{ __html: (activity as NoteActivity).title }}></Box>
            </CardContent>
          </Card>
        </Box>
      case 'call':
        return <Box width="100%" display="flex" alignItems="center" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            marginTop="20px"
            alignItems="start">
            <Box top="-15px" display="flex" sx={{ background: "white" }} position="absolute" padding="5px" borderRadius="50%" boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
              <PhoneOutlinedIcon sx={{ color: "#A0A0A0" }} />
            </Box>
          </Box>
          <Card
            elevation={3}
            style={{
              width: "90%",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
            }}>
            <CardContent sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Typography variant="h5" component="div">
                {(activity as CallActivity).title}
              </Typography>
              <Box marginTop="15px" display="block" dangerouslySetInnerHTML={{ __html: (activity as CallActivity).content }}></Box>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <audio style={{ width: "300px", height: "30px" }} controls src={(activity as CallActivity).recordingUrl}></audio>
            </CardActions>
          </Card>
        </Box>
      case 'meeting':
        return <Box width="100%" display="flex" alignItems="center" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            marginTop="20px"
            alignItems="start">
            <Box top="-15px" display="flex" sx={{ background: "white" }} position="absolute" padding="5px" borderRadius="50%" boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
              <GroupsOutlinedIcon sx={{ color: "#A0A0A0" }} />
            </Box>
          </Box>
          <Card
            elevation={3}
            style={{
              width: "90%",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
            }}
          >
            <CardContent sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Typography variant="h5" component="div">
                {(activity as MeetingActivity).title}
              </Typography>
              <Box dangerouslySetInnerHTML={{ __html: (activity as MeetingActivity).content }}></Box>
              <Box dangerouslySetInnerHTML={{ __html: (activity as MeetingActivity).notes }}></Box>
            </CardContent>
          </Card>
        </Box>
      case 'task':
        return <Box width="100%" display="flex" alignItems="center" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            marginTop="20px"
            alignItems="start">
            <Box top="-15px" display="flex" sx={{ background: "white" }} position="absolute" padding="5px" borderRadius="50%" boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
              <TaskOutlinedIcon sx={{ color: "#A0A0A0" }} />
            </Box>
          </Box>

          <Card
            elevation={3}
            style={{
              width: "90%",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
            }}>
            <Box display="flex" justifyContent="flex-end">
              <Chip label={(activity as TaskActivity).status} variant="outlined" size="small" />
            </Box>
            <CardContent sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Typography variant="h5" component="div">
                {(activity as TaskActivity).title}
              </Typography>
              <Box display="block" whiteSpace="pre-line" dangerouslySetInnerHTML={{ __html: (activity as TaskActivity).content }}></Box>
            </CardContent>
          </Card>
        </Box>
      default:
      case 'email':
        return <Box width="100%" display="flex" alignItems="center" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            position="relative"
            marginTop="20px"
            alignItems="start">
            <Box top="-15px" display="flex" sx={{ background: "white" }} position="absolute" padding="5px" borderRadius="50%" boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px">
              <MailOutlineIcon sx={{ color: "#A0A0A0" }} />
            </Box>
          </Box>
          <Card
            elevation={3}
            style={{
              width: "90%",
              borderRadius: "10px",
              padding: "15px",
              textAlign: "center",
            }}>
            <CardContent sx={{ overflow: "auto", maxHeight: "600px" }}>
              <Typography variant="h5" component="div">
                {(activity as EmailActivity).title}
              </Typography>
              <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" marginTop="15px">
                <Box display="flex">
                  <Box display="flex">
                    <Avatar {...stringAvatar((activity as EmailActivity).senderFirstName + ' ' + (activity as EmailActivity).senderLastName)} />
                  </Box>
                  <Box display="flex">
                    <Box display="flex" flexDirection="column" marginLeft="10px">
                      <Box display="flex">
                        {(activity as EmailActivity).senderFirstName ?? 'Undefined'}&nbsp;{(activity as EmailActivity).senderLastName}
                      </Box>
                      <Box display="flex" color="#8D8D8D">
                        to {(activity as EmailActivity).toFirstName}&nbsp;{(activity as EmailActivity).toLastName}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" color="#8D8D8D" alignItems="flex-end">
                  {format(new Date((activity as EmailActivity).createDate), 'PPP')}
                </Box>
              </Box>
              <Box display="flex">
                <Box whiteSpace="pre-line">{email}</Box>
              </Box>
              <Box display="flex" marginTop="15px">
                {!email && <Link sx={{ color: '#4338CA' }} component="button" onClick={viewEmail}>View all</Link>}
              </Box>
            </CardContent>
          </Card>
        </Box>
    }
  }, [email, viewEmail])

  const left = useCallback(() => {
    setEmail('');
    const currentItemIndex = props.activities.findIndex(a => a.id === currentActivity.id);
    setCurrentActivity(props.activities[currentItemIndex - 1]);
  }, [currentActivity.id, props.activities])

  const right = useCallback(() => {
    setEmail('');
    const currentItemIndex = props.activities.findIndex(a => a.id === currentActivity.id);
    setCurrentActivity(props.activities[currentItemIndex + 1]);
  }, [currentActivity.id, props.activities])

  const isLeftDisabled = useCallback(() => {
    const currentItemIndex = props.activities.findIndex(a => a.id === currentActivity.id);
    if (!props.activities[currentItemIndex - 1]) {
      return true;
    }
    return false;
  }, [currentActivity.id, props.activities])

  const isRightDisabled = useCallback(() => {
    const currentItemIndex = props.activities.findIndex(a => a.id === currentActivity.id);
    if (!props.activities[currentItemIndex + 1]) {
      return true;
    }
    return false;
  }, [currentActivity.id, props.activities])

  return <>{props.activities &&
    <>
      <Box width="100%" display="flex" flexDirection="column">
        <Box>
          <IconButton aria-label="close" sx={{ width: '24px', height: '24px' }} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" margin="10px" gap="10px" marginLeft="40px">
          <Box fontWeight="bold" fontSize="18px">
            Touchpoint Detail
          </Box>
        </Box>
      </Box>
      <Box height="100%" display="flex" flexDirection="column" alignItems="center">
        <Chip label={format(new Date(currentActivity.createDate), 'PPP')} variant="outlined" sx={{ marginBottom: "20px" }} />
        {getCard(currentActivity)}
        <Box display="flex" justifyContent="flex-end" width="90%">
          <IconButton aria-label="left" sx={{ color: '#4338CA' }} onClick={left} disabled={isLeftDisabled()}>
            <ChevronLeftIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton aria-label="right" sx={{ color: '#4338CA' }} onClick={right} disabled={isRightDisabled()}>
            <ChevronRightIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      </Box>
    </>
  }</>
};
